const polishCharReplacements = {
    'ę': 'e',
    'ą': 'a',
    'ó': 'o',
    'ś': 's',
    'ł': 'l',
    'ż': 'z',
    'ź': 'z',
    'ć': 'c',
    'ń': 'n',
    'Ę': 'E',
    'Ą': 'A',
    'Ó': 'O',
    'Ś': 'S',
    'Ł': 'L',
    'Ż': 'Z',
    'Ź': 'Z',
    'Ć': 'C',
    'Ń': 'N',
}

const createSlug = (originalString, {
    lowerCase = true,
    spaceReplacement = '-',
    additionalyAllowed = [],
} = {}) => {

    if (typeof originalString !== 'string' || originalString.length < 1) return ''

    const sentence = lowerCase
        ? originalString.toLowerCase()
        : originalString

    const letterCount = sentence.length
    const letterCases = lowerCase
        ? 'a-z'
        : 'a-zA-Z'

    const result = []
    const additionalyAllowedGroup = additionalyAllowed.map(chr => `\\${chr}`)
    const allowedCharRegexp = new RegExp(`^[${letterCases}0-9\\${spaceReplacement}${additionalyAllowedGroup}]$`)

    for (let i = 0; i < letterCount; i++) {
        const char = sentence[i]

        const polishReplacement = polishCharReplacements[char]
        if (polishReplacement) {
            result.push(polishReplacement)
            continue
        }

        const isSpace = char === ' ' || char === spaceReplacement
        if (isSpace) {
            if (result[result.length - 1] !== spaceReplacement) {
                result.push(spaceReplacement)
            }
            continue
        }

        const isAllowed = allowedCharRegexp.test(char)
        if (!isAllowed) {
            continue
        }

        result.push(char)
    }

    return result.join('')
}

module.exports = createSlug