"use client"

import { useMemo, useState } from 'react'
import Image from 'next/legacy/image'

import styles from './WithFallback.module.css'

const ImageWithFallback = ({
    alt,
    className,
    customFallbackResolver,
    fallback = '/fallback/list.png',
    objectFit,
    priority,
    spinner = true,
    src,
    useNextImage = true,
    ...rest
}) => {
    const [imgSrc, setImgSrc] = useState(src)

    const fallbackSrc = useMemo(() =>
        customFallbackResolver && customFallbackResolver() || fallback || '/fallback/list.png', [fallback, customFallbackResolver])

    const imageProps = {
        ...rest,
        alt,
        className,
        onError: (e) => {
            e.target.id = fallbackSrc
            e.target.srcset = fallbackSrc
            setImgSrc(fallbackSrc)
        },
        priority: useNextImage ? priority : (priority ? 'eager' : undefined),
        src: imgSrc,
    }

    return useNextImage
        ? <Image {...imageProps} objectFit={objectFit} />

        : <div className={styles.wrap} data-cf="true">
            <img {...imageProps} />
          </div>
}

export default ImageWithFallback