import Image from 'next/image'
import Icon from '@next-spl-monorepo/shared-components/icon/Icon'

const HOST = process.env.NEXT_PUBLIC_HOST
const ASSET_PUBLIC_DIR = '/icons'
const SVG = {
    login: {
        alt: 'Zaloguj się',
        src: HOST + ASSET_PUBLIC_DIR + '/login.svg',
        w: 20,
        h: 22,
    },

    zoom: {
        alt: 'Szukaj',
        src: HOST + ASSET_PUBLIC_DIR + '/zoom.svg',
        w: 23,
        h: 23,
    },
}

export default function resolveIcon (icon, iconAlt) {
    if (typeof icon !== 'string') return null

    const svgPath = SVG[icon]
    if (svgPath) {
        const { alt, src, w, h } = svgPath
        return <Image alt={iconAlt || alt} src={src} width={w} height={h} />

    } else {
        return <Icon style={{margin:0}}>{icon}</Icon>
    }
}