"use client"

import { useCallback, useEffect, useState } from 'react'

const useMediaQueryBreakpoint = (width, defaultValue) => {
    const [hit, setHit] = useState(typeof defaultValue === 'boolean' ? defaultValue : 1)

    const updateBreakpoint = useCallback((e) => {
        setHit(Boolean(e.matches))
    }, [])

    useEffect(() => {
        const media = window.matchMedia(`(max-width: ${width}px)`)
        media.addEventListener('change', updateBreakpoint)
        setHit(Boolean(media.matches))

        return () => media.removeEventListener('change', updateBreakpoint)
    }, [updateBreakpoint, width])

    return hit
}

export default useMediaQueryBreakpoint