const ListOut = ({ id, items, Component, ...rest }) => {
  return Array.isArray(items) && items.length > 0

      ? items.map((item, i) => item

        ? <Component
            key={item[id] || `_${i}`}
            {...item}
            {...rest}
            i={i}
          />

        : null)

      : null
}

export default ListOut