export default function resolveColors (props, availableColorsObject, styles) {
    if (typeof props !== 'object') return {}

    const colorClasses = []
    const otherProps = {}
    for (const key in props) {
        if (key in availableColorsObject && key in styles) {
            colorClasses.push(styles[key])
        } else {
            otherProps[key] = props[key]
        }
    }
    return [colorClasses, otherProps]
}