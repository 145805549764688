const HOST = process.env.NEXT_PUBLIC_HOST
const FORUM_PAGE = HOST.replace('//', '//forum.')
const WORK_PAGE = HOST.replace('//', '//praca.')
const BUY_CAR_PAGE = '/kupie-samochod-auto'
const SELL_PAGE = '/sprzedaz-samochodow-uzywanych-nowych'
const NEWS_PAGE = '/blog'
const LOCAL_OFFER_PAGE = '/w-twojej-okolicy/samochody-osobowe'
const FIRM_BASE_PAGE = '/baza-firm'
const SALONS_PAGE = '/salony'
const CONTACT_PAGE = '/kontakt-ogloszenia-sprzedazy'
const ABOUT_US_PAGE = '/o-nas-ogloszenia-motoryzacja'
const PRICING_PAGE = '/cennik-ogloszenia-motoryzacja'
const API_DOCS_PAGE = '/dokumentacja-api'
const PRIVACY_PAGE = '/polityka-prywatnosci'
const REGULATIONS_PAGE = '/regulamin'
const INTEGRATION_PAGE = '/integracje-z-samochody'
const SALE_DOC_PAGE = '/umowa-kupna-sprzedazy'
const COLISION_DOC_PAGE = '/oswiadczenie-sprawcy-kolizji'
const SALE_BUY_DOC_PAGE = '/zgloszenie-sprzedazy-zbycia-nabycia-pojazdu-samochodu-auta'
const AKOL_PARTNERSHIP_PAGE = '/wspolpraca-partnerska'
const INDUSTRY_GROUP_PAGE = '/grupa-branzowa'
const PUNKTA_PAGE = '/zarabiam-na-punkta'
const COMPERIA_PAGE = '/finansowanie'
const DAT_PAGE = '/wycena-szkody'
const DAT_SUCCESS_PAGE = `${DAT_PAGE}/gotowe`
const DAT_MAKE_PAGE = '/documents/dat/modele.pdf'

const USED_CAR_PAGE = '/samochody-osobowe/uzywane'
const ADD_OFFER_PAGE = '/dodaj-ogloszenie/sprzedaj/samochod-osobowy'
const LOGIN_PAGE = '/konto/zaloguj'
const NEW_ACCOUNT_PAGE = '/konto/nowe-konto' // changed!
const NEW_DEALER_ACCOUNT_PAGE = '/konto/nowe-konto-firmowe' // changed!
const NEW_PARTNER_ACCOUNT_PAGE = '/konto/nowe-konto-partnera' // changed!
const LOGOUT_PAGE = '/konto/wyloguj' // changed!
const CONFIRM_REGISTRATION_PAGE = '/konto/pomyslna-rejestracja' // changed!
const CONFIRM_VERIFICATION_PAGE = '/konto/pomyslna-weryfikacja' // changed!
const CONFIRM_ACCOUNT_DELETION_PAGE = '/konto/potwierdz-usuniecie-konta' // changed!
const ACCOUNT_DELETED_PAGE = '/konto/konto-usuniete' // changed!
const VERIFICATION_PAGE = '/api/konto/weryfikacja-konta' // changed!
const SET_NEW_PASSWORD_PAGE = '/konto/ustaw-nowe-haslo' // changed!
const SEND_PAYMENT_LINK_PAGE = '/link-do-platnosci'

const USER_MY_DATA = '/profil/moje-dane'
    const USER_MY_DATA_LOCATION = `${USER_MY_DATA}/lokalizacja`
    const USER_MY_DATA_PASSWORD = `${USER_MY_DATA}/haslo`
    const USER_MY_DATA_DEALER = `${USER_MY_DATA}/dealer`
    const USER_MY_DATA_PACKETS = `${USER_MY_DATA}/pakiety`
    const USER_MY_DATA_INVOICES = `${USER_MY_DATA}/faktury`
    const USER_MY_DATA_INTEGRATIONS = `${USER_MY_DATA}/integracje`
    const USER_MY_DATA_PROMO_CODES = `${USER_MY_DATA}/kody-rabatowe`
    const USER_MY_DATE_INK_DEAL = `${USER_MY_DATA}/potwierdzenie`
    const USER_MY_DATA_INSURANCE = `${USER_MY_DATA}/ubezpieczenia`
    const USER_MY_DATA_DAT_PAGE = `${USER_MY_DATA}/dat`
const USER_MY_OFFERS = '/profil/moje-ogloszenia'
    const USER_MY_OFFERS_EDIT = `${USER_MY_OFFERS}/edytuj`
    const USER_MY_OFFERS_POST_SIMILAR = `${USER_MY_OFFERS}/wystaw-podobne`
const USER_MY_BUY_OFFERS = '/profil/moje-oferty-kupna'
    const USER_MY_BUY_OFFERS_EDIT = `${USER_MY_BUY_OFFERS}/edytuj`
const USER_SUBSCRIPTIONS = '/profil/subskrypcje'
    const USER_SUBSCRIPTIONS_SEND_MESSAGE = `${USER_SUBSCRIPTIONS}/wyslij-wiadomosc`
    const USER_SUBSCRIPTIONS_SENT_MESSAGES = `${USER_SUBSCRIPTIONS}/wyslane-wiadomosci`
    const USER_SUBSCRIPTIONS_RECIEVED_MESSAGES = `${USER_SUBSCRIPTIONS}/otrzymane-wiadomosci`
    const USER_SUBSCRIPTIONS_MY_SUBSCRIBERS = `${USER_SUBSCRIPTIONS}/moi-subskrybenci`
    const USER_SUBSCRIPTIONS_SUBSCRIBED_SALONS = `${USER_SUBSCRIPTIONS}/subskrybowane-salony`
const USER_DEALER = '/profil/dealer'
    const USER_DEALER_SUBDOMAIN = `${USER_DEALER}/subdomena`
    const USER_DEALER_GREETING_CARD = `${USER_DEALER}/wizytowka`
    const USER_DEALER_QR_CODE = `${USER_DEALER}/twoj-qr-kod`
    const USER_DEALER_CONTENT = `${USER_DEALER}/tresc`
    const USER_DEALER_CLIENTS = `${USER_DEALER}/klienci`
const USER_WALLET = '/profil/moj-portfel'
const USER_WATCHED = '/profil/obserwowane'
    const USER_WATCHED_CONFIGURATIONS = `${USER_WATCHED}/poszukiwane-konfiguracje` //
    const USER_WATCHED_OFFERS = `${USER_WATCHED}/ogloszenia`
    const USER_WATCHED_SEARCHES = `${USER_WATCHED}/wyszukiwania`
    const USER_WATCHED_SALONS = `${USER_WATCHED}/salony`
const USER_PARTNER = '/profil/partner'
    const USER_PARTNER_SUBDOMAIN = `${USER_PARTNER}/subdomena`
    const USER_PARTNER_GREETING_CARD = `${USER_PARTNER}/wizytowka`
    const USER_PARTNER_CLIENTS = `${USER_PARTNER}/klienci`
    const ADD_PARTNER_PRODUCT = `${USER_PARTNER}/dodaj-produkt-partnera`
    const ADD_PARTNER_ARTICLE = `${USER_PARTNER}/dodaj-artykul-partnera`
    const USER_MY_PRODUCTS = `${USER_PARTNER}/moje-produkty`
    const USER_MY_ARTICLES = `${USER_PARTNER}/moje-artykuly`

const PARTNER_PAGE = '/partner'
const PARTNER_PAGE_PRODUCT = '/produkt/:slug'
const PARTNER_PAGE_ARTICLE = '/artykul/:slug'

const SITE_MAPS_PAGE = '/mapa-strony'
const ALL_FEATURED_PAGE = '/promowane-ogloszenia'
const API_DOCS_PARAMS = `${API_DOCS_PAGE}/:version/:chapter/:lang`
const SPL_DEAL_CONFIRMATION_INSTRUCTIONS_PAGE = '/potwierdzenie-umowy-z-samochody-pl-instrukcje'

module.exports = {
    FORUM_PAGE,
    WORK_PAGE,

    BUY_CAR_PAGE,
    SELL_PAGE,
    NEWS_PAGE,
    LOCAL_OFFER_PAGE,
    FIRM_BASE_PAGE,
    SALONS_PAGE,
    CONTACT_PAGE,
    ABOUT_US_PAGE,
    PRICING_PAGE,
    API_DOCS_PAGE,
    PRIVACY_PAGE,
    REGULATIONS_PAGE,
    INTEGRATION_PAGE,
    SALE_DOC_PAGE,
    COLISION_DOC_PAGE,
    SALE_BUY_DOC_PAGE,
    AKOL_PARTNERSHIP_PAGE,
    INDUSTRY_GROUP_PAGE,
    PUNKTA_PAGE,
    COMPERIA_PAGE,
    DAT_PAGE,
    DAT_MAKE_PAGE,
    DAT_SUCCESS_PAGE,

    USED_CAR_PAGE,

    ADD_OFFER_PAGE,
    LOGIN_PAGE,
    NEW_ACCOUNT_PAGE,
    NEW_DEALER_ACCOUNT_PAGE,
    NEW_PARTNER_ACCOUNT_PAGE,
    LOGOUT_PAGE,
    CONFIRM_REGISTRATION_PAGE,
    CONFIRM_VERIFICATION_PAGE,
    CONFIRM_ACCOUNT_DELETION_PAGE,
    ACCOUNT_DELETED_PAGE,
    VERIFICATION_PAGE,
    SET_NEW_PASSWORD_PAGE,
    SEND_PAYMENT_LINK_PAGE,

    USER_MY_DATA,
    USER_MY_DATA_LOCATION,
    USER_MY_DATA_PASSWORD,
    USER_MY_DATA_DEALER,
    USER_MY_DATA_PACKETS,
    USER_MY_DATA_INVOICES,
    USER_MY_DATA_INTEGRATIONS,
    USER_MY_DATA_PROMO_CODES,
    USER_MY_DATE_INK_DEAL,
    USER_MY_DATA_INSURANCE,
    USER_MY_DATA_DAT_PAGE,

    USER_MY_OFFERS,
    USER_MY_OFFERS_EDIT,
    USER_MY_OFFERS_POST_SIMILAR,
    USER_MY_BUY_OFFERS,
    USER_MY_BUY_OFFERS_EDIT,
    USER_SUBSCRIPTIONS,
    USER_SUBSCRIPTIONS_SEND_MESSAGE,
    USER_SUBSCRIPTIONS_SENT_MESSAGES,
    USER_SUBSCRIPTIONS_RECIEVED_MESSAGES,
    USER_SUBSCRIPTIONS_MY_SUBSCRIBERS,
    USER_SUBSCRIPTIONS_SUBSCRIBED_SALONS,
    USER_DEALER,
    USER_DEALER_SUBDOMAIN,
    USER_DEALER_GREETING_CARD,
    USER_DEALER_QR_CODE,
    USER_DEALER_CONTENT,
    USER_DEALER_CLIENTS,
    USER_WALLET,

    USER_WATCHED,
    USER_WATCHED_CONFIGURATIONS,
    USER_WATCHED_OFFERS,
    USER_WATCHED_SEARCHES,
    USER_WATCHED_SALONS,

    USER_PARTNER,
    USER_PARTNER_SUBDOMAIN,
    USER_PARTNER_GREETING_CARD,
    USER_PARTNER_CLIENTS,

    USER_MY_PRODUCTS,
    USER_MY_ARTICLES,
    ADD_PARTNER_PRODUCT,
    ADD_PARTNER_ARTICLE,
    PARTNER_PAGE,
    PARTNER_PAGE_PRODUCT,
    PARTNER_PAGE_ARTICLE,
    SITE_MAPS_PAGE,
    API_DOCS_PARAMS,
    ALL_FEATURED_PAGE,
    SPL_DEAL_CONFIRMATION_INSTRUCTIONS_PAGE,
}